<template>
    <div class="container repair-container">

        <!-- Checklist -->
        <label>Complete the following tests</label>
        <ul class="list-group selection-list mb-5">
            <!-- TODO: Apple and Samsung -->
            <li class="list-group-item" :class="{'active': visualInspectionTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="visual-inspection-test" v-model="visualInspectionTest">
                    <label class="custom-control-label mb-0" for="visual-inspection-test">Carry out visual inspection</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': touchscreenTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="touchscreen-test" v-model="touchscreenTest">
                    <label class="custom-control-label mb-0" for="touchscreen-test">Touchscreen by dragging app across the entire screen to check for any dead spots (if screen intact)</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': lcdWhiteBackgroundTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="lcd-white-background-test" v-model="lcdWhiteBackgroundTest">
                    <label class="custom-control-label mb-0" for="lcd-white-background-test">LCD on white background for any abnormalities (if screen intact)</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': touchIdFaceIdTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="touch-id-face-id-test" v-model="touchIdFaceIdTest">
                    <label class="custom-control-label mb-0" for="touch-id-face-id-test">Touch ID/ face ID</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': earSpeakerTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="ear-speaker-test" v-model="earSpeakerTest">
                    <label class="custom-control-label mb-0" for="ear-speaker-test">Ear speaker and proximity sensor (ring 1234)</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': frontRearCameraTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="front-rear-camera-test" v-model="frontRearCameraTest">
                    <label class="custom-control-label mb-0" for="front-rear-camera-test">Front facing and rear camera</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': microphoneLoudspeakerTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="microphone-loudspeaker-test" v-model="microphoneLoudspeakerTest">
                    <label class="custom-control-label mb-0" for="microphone-loudspeaker-test">Microphone and loudspeaker (recording voice memo and play back)</label>
                </label>
            </li>
            <!-- TODO: Apple -->
            <li class="list-group-item" :class="{'active': appleChargeTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="apple-charge-test" v-model="appleChargeTest">
                    <label class="custom-control-label mb-0" for="apple-charge-test">Plug phone in to check charging</label>
                </label>
            </li>
            <!-- TODO: Samsung -->
            <li class="list-group-item" :class="{'active': testMenu0Test}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="test-menu-0-test" v-model="testMenu0Test">
                    <label class="custom-control-label mb-0" for="test-menu-0-test">All tests in *#0*# test menu</label>
                </label>
            </li>
            <!-- TODO: Samsung -->
            <li class="list-group-item" :class="{'active': testMenu0283Test}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="test-menu-0283-test" v-model="testMenu0283Test">
                    <label class="custom-control-label mb-0" for="test-menu-0283-test">All tests in *#0283# test menu</label>
                </label>
            </li>
            <!-- TODO: Samsung -->
            <li class="list-group-item" :class="{'active': rootKnoxTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="root-knox-test" v-model="rootKnoxTest">
                    <label class="custom-control-label mb-0" for="root-knox-test">Perform a Root/Knox check (Put the phone in download mode and check knox warranty void displays as all zeros)</label>
                </label>
            </li>
            <!-- TODO: Samsung -->
            <li class="list-group-item" :class="{'active': galaxyDiagnosticsOqcTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="galaxy-diagnostics-oqc-test" v-model="galaxyDiagnosticsOqcTest">
                    <label class="custom-control-label mb-0" for="galaxy-diagnostics-oqc-test">Run Galaxy Diagnostics OQC</label>
                </label>
            </li>
            <!-- TODO: Samsung -->
            <li class="list-group-item" :class="{'active': samsungChargeTest}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="samsung-charge-test" v-model="samsungChargeTest">
                    <label class="custom-control-label mb-0" for="samsung-charge-test">Make sure phone charges normally (fast, normal, wireless)</label>
                </label>
            </li>
            <!-- TODO: Samsung - this is unique to this page and applies to out of warranty only (I believe) -->
            <li class="list-group-item" :class="{'active': sVCConnectOnFenrir}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="svc-connect-on-fenrir" v-model="sVCConnectOnFenrir">
                    <label class="custom-control-label mb-0" for="svc-connect-on-fenrir">Carry out an SVC connect on Fenrir</label>
                </label>
            </li>
            <!-- TODO: Samsung - this is unique to this page and applies to in warranty only -->
            <li class="list-group-item" :class="{'active': carryOutFlash}">
                <label class="custom-control custom-checkbox mb-0">
                    <input type="checkbox" class="custom-control-input" id="carry-out-flash" v-model="carryOutFlash">
                    <label class="custom-control-label mb-0" for="carry-out-flash">Carry out a flash</label>
                </label>
            </li>
        </ul>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': footerActive}"
                nextLabel="Take after pictures"
                nextRoute="repair.afterPictures">
        </fixed-footer>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                visualInspectionTest: false,

                touchscreenTest: false,
                lcdWhiteBackgroundTest: false,
                touchIdFaceIdTest: false,
                earSpeakerTest: false,
                frontRearCameraTest: false,
                microphoneLoudspeakerTest: false,
                appleChargeTest: false,

                testMenu0Test: false,
                testMenu0283Test: false,
                rootKnoxTest: false,
                galaxyDiagnosticsOqcTest: false,
                samsungChargeTest: false,
                sVCConnectOnFenrir: false,
                carryOutFlash: false,

                footerActive: true
            }
        }
    }
</script>

<style scoped>

</style>